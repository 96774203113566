import React from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { TextInput } from 'components/common/Input/TextInput';
import { providerPasswordSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { useMutationWithToast } from 'hooks/useMutationWithToast';
import { UserService } from 'services/user';
import { FieldError, InputFieldType } from 'types/common';

import { useOnboardProviderFormContext } from './OnboardFormContext';
import {
  getNavigationTab,
  getSearchKeyNameFromError,
} from './validation-navigation-helper';

export function CreatePassword() {
  const providerOnboardMutation = useMutationWithToast(
    UserService.onBoardProvider,
    {}
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const { formState, handleFormErrors } = useOnboardProviderFormContext();
  const { control, handleSubmit } = useFormWithErrors<{ password: string }>({
    mutation: providerOnboardMutation,
    schema: providerPasswordSchema,
  });

  const onSubmit = handleSubmit((data) => {
    localStorage.removeItem('login_redirect');
    const { basicInformation, contactDetails, npiDeaLicense, specialities } =
      formState;

    if (basicInformation && contactDetails && npiDeaLicense && specialities) {
      const { firstName, lastName, providerTitle, dob, gender, middleName } =
        basicInformation;
      const {
        officeManagerEmail,
        officeManagerName,
        phoneNumber,
        offersTelemedicine,
      } = contactDetails;
      const { licensesDeas, npi } = npiDeaLicense;

      providerOnboardMutation.mutate(
        {
          token: token || '',
          providerDetails: {
            firstName,
            lastName,
            licensesDeas,
            middleName,
            specialities: specialities.map((s) => ({ id: s })),
            phone: `+${phoneNumber}`,
            password: data.password,
            details: {
              dob,
              gender,
              npi: Number(npi),
              offersTelemedicine,
              officeManagerEmail,
              officeManagerName,
              providerSuffix: providerTitle,
            },
          },
        },
        {
          onSuccess: () => {
            localStorage.removeItem('providerOnboardSteps');
            localStorage.removeItem('deaLicenseData');
            localStorage.removeItem('onboardToken');
            navigate({ pathname: '/login' });
          },
          onError(error) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const errorObj: any = getSearchKeyNameFromError(
              error.response?.data.fieldErrors as unknown as {
                [x: string]: FieldError;
              }
            );

            handleFormErrors(errorObj as { [key: string]: string });

            if (errorObj?.searchKey) {
              const link = getNavigationTab(formState, errorObj.searchKey);
              navigate({
                pathname: `../${link}`,
                search: `token=${token}`,
              });
            }
          },
        }
      );
    }
  });

  return (
    <div className="mt-4 ">
      {' '}
      <div className="grid grid-cols-12 gap-x-4 ">
        <div className="col-span-12  flex gap-x-4 lg:col-span-7">
          <TextInput
            required
            label="Password"
            type={InputFieldType.Password}
            name="password"
            control={control}
            placeholder="Password"
            fullWidth
          />
        </div>{' '}
      </div>
      <div className="grid grid-cols-12 gap-x-4 ">
        {' '}
        <div className="col-span-12  flex gap-x-4 lg:col-span-7">
          <TextInput
            required
            label="Confirm Password"
            type={InputFieldType.Password}
            name="confirmPassword"
            control={control}
            placeholder="Confirm Password"
            fullWidth
          />
        </div>
      </div>
      <div className="mt-8 flex gap-4 ">
        <Button
          variant="outlined"
          onClick={() => {
            navigate({
              pathname: '../contact-details',
              search: `token=${token}`,
            });
          }}>
          Go Back
        </Button>{' '}
        <Button onClick={onSubmit} loading={providerOnboardMutation.isLoading}>
          Create Account
        </Button>
      </div>
    </div>
  );
}
