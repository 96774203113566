import React, { useEffect } from 'react';

import {
  NavLink,
  Outlet,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

// eslint-disable-next-line
import LogoGreen from 'assets/images/LogoGreen(OnLeft).svg';
import { Typography } from 'components/common/Typography/Typography';
import { OnboardFormProvider } from 'components/ProviderOnboard2/OnboardFormContext';

export const tabs = [
  {
    title: 'Profile Picture',
    link: 'profile-picture',
    key: 'picture',
  },
  {
    title: 'Basic Information',
    link: 'basic-information',
    key: 'basicInformation',
  },
  { title: 'Specialties', link: 'specialties', key: 'specialities' },
  {
    title: 'NPI, DEA & Licenses',
    link: 'npi-dea-license',
    key: 'npiDeaLicense',
  },
  {
    title: 'Contact Details & Telemedicine Services',
    link: 'contact-details',
    key: 'contactDetails',
  },
  { title: 'Create Password', link: 'create-password', key: 'password' },
];

export function ProviderOnboard2() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token') ?? '';
  const navigate = useNavigate();

  useEffect(() => {
    if (tabs[0]) {
      navigate({ pathname: tabs[0].link, search: `token=${token}` });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OnboardFormProvider>
      <div className="min-h-screen bg-background-dark p-4 pt-8  sm:p-16 lg:p-32 lg:pt-14 xl:p-48 xl:pt-14   ">
        <div className="flex justify-center">
          {' '}
          <img className="h-14 rounded" src={LogoGreen} alt="logo" />
        </div>
        <div className="mt-6 flex flex-col items-center sm:items-start lg:mt-10  ">
          <Typography className="!text-2xl lg:!text-3xl">
            Create an account to get started.
          </Typography>
          <Typography className=" mt-2 text-center !text-lg sm:text-left lg:!text-xl">
            Sign up with us to unlock a seamless experience. Creating an account
            is the first step towards providing world class therapy.
          </Typography>
        </div>
        <div className="mt-20 flex ">
          <div>
            <div className="mt-2 flex cursor-pointer flex-col  text-background-contrastText">
              {tabs.map((tab) => (
                <div className="pointer-events-none cursor-auto">
                  <NavLink
                    key={tab.title}
                    to={{ pathname: tab.link, search: `token=${token}` }}
                    className={({ isActive }) =>
                      `inline-block py-4 pl-1   ${
                        isActive
                          ? 'text-primary-main '
                          : 'text-background-contrastText '
                      }`
                    }>
                    <Typography
                      className="w-48"
                      style={{ color: 'inherit' }}
                      variant="subtitle1">
                      {tab.title}
                    </Typography>
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
          <span className="mx-[4rem] mt-2 h-[30rem] w-[2px] bg-background-contrastText " />

          <div className=" grow">
            <Outlet />
          </div>
        </div>
      </div>
    </OnboardFormProvider>
  );
}
