import React, { useEffect, useMemo, useState } from 'react';

import { TrashIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from 'components/common/Button/Button';
import { MultiSelectDropDown } from 'components/common/MultiSelectDropDown/MultiSelectDropDown';
import { Table } from 'components/common/Table/Table';
import { QueryKeys } from 'constants/query-keys';
import { providerSpecialitiesSchema } from 'constants/validation-schema';
import { useFormWithErrors } from 'hooks/useFormWithErrors';
import { UserService } from 'services/user';

import { useOnboardProviderFormContext } from './OnboardFormContext';

type Speciality = { title: string; value: string };

export function Specialties() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token');
  const getProviderSpecialtiesQuery = useQuery(
    [QueryKeys.ProviderSpecialties],
    UserService.getProviderSpecialties,
    {
      staleTime: Infinity,
      retry: false,
    }
  );

  const specialityOptions = getProviderSpecialtiesQuery.data?.data;

  const { control, watch, handleSubmit, setValue } = useFormWithErrors({
    schema: providerSpecialitiesSchema,
  });

  const { formState, updateFormState } = useOnboardProviderFormContext();

  const [providerSpecialities, setProviderSpecialities] =
    useState<Speciality[]>();

  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (formState.specialities) {
      setValue('specialities', formState.specialities);
    }
  }, [formState, setValue]);

  const specialties = watch('specialities');

  useEffect(() => {
    handleDataForTableView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialties, specialityOptions]);

  const handleDataForTableView = () => {
    const specialityArr = specialties?.map((id: string) => {
      const foundItem = specialityOptions?.find(
        (item: { name: string; id: string }) => item.id === id
      );
      if (foundItem) {
        return { title: foundItem.name, value: foundItem.id };
      }

      return null; // or return some default value or simply omit this line if you want to filter out unmatched ids
    });

    setProviderSpecialities(specialityArr);
  };

  const onSubmit = handleSubmit((data) => {
    if (providerSpecialities?.length === 0) {
      toast.error('Please select atleast one speciality');

      return;
    }

    updateFormState('specialities', data.specialities);

    navigate({
      pathname: '../npi-dea-license',
      search: `token=${token}`,
    });
  });

  const handleDelete = (id: string) => {
    const index = providerSpecialities?.findIndex((item) => item.value === id);

    if (Number(index) > -1) {
      const tempSpecialities = [...(providerSpecialities || [])];
      tempSpecialities.splice(Number(index), 1);
      updateFormState(
        'specialities',
        tempSpecialities.map((item) => item.value)
      );
      setProviderSpecialities(tempSpecialities);
    }
  };

  const tableRowData: Speciality[] = useMemo(
    () =>
      providerSpecialities?.slice(
        page === 1 ? 0 : (page - 1) * 10,
        page * 10
      ) || [],
    [providerSpecialities, page]
  );

  const onPageChange = (pageCount: number) => {
    setPage(pageCount);
  };

  return (
    <div>
      <div className="mt-4 grid grid-cols-12 gap-x-4">
        {' '}
        <div className="col-span-7 lg:col-span-10">
          <div className="flex items-center">
            {specialityOptions?.length ? (
              <MultiSelectDropDown
                required
                searchable
                label="Select Specialties"
                name="specialities"
                defaultValue={
                  providerSpecialities?.length && providerSpecialities?.[0]
                    ? providerSpecialities
                    : []
                }
                className="z-50"
                fullWidth
                control={control}
                options={specialityOptions.map((item) => ({
                  title: item.name,
                  value: item.id,
                }))}
              />
            ) : null}
          </div>
        </div>
        <div className=" col-span-7 mt-2 flex flex-col overflow-auto lg:col-span-10">
          <Table
            rowsData={providerSpecialities?.length ? tableRowData : []}
            pagination
            page={page}
            recordsPerPage={10}
            totalRecords={providerSpecialities?.length || 0}
            onPageChange={onPageChange}
            columns={[
              {
                title: 'Speciality',
                render: (rowData) => `${rowData?.title}`,
                classNames: 'text-start',
              },
              {
                title: 'Action',
                render: (rowData) => (
                  <TrashIcon
                    className="text-white-400 ml-1 h-4 cursor-pointer"
                    onClick={() => {
                      handleDelete(rowData?.value);
                    }}
                  />
                ),
                classNames: 'text-start',
              },
            ]}
            noDataMessage=" You currently have no records"
          />
        </div>
      </div>
      <div className="mt-8 flex gap-4 ">
        <Button
          variant="outlined"
          onClick={() => {
            navigate({
              pathname: '../basic-information',
              search: `token=${token}`,
            });
          }}>
          Go Back
        </Button>{' '}
        <Button onClick={onSubmit}>Next</Button>
      </div>
    </div>
  );
}
